
          @import "./src/styles/variables/theme.scss";
          @import "../node_modules/bulma/sass/utilities/initial-variables.sass";
          @import "../node_modules/bulma/sass/utilities/mixins.sass";
          @import "./src/styles/variables/mixins.scss";
        
@import './../../../../../node_modules/bulma/sass/utilities/mixins.sass';
@import './../../../styles/variables/theme.scss';
@import './../../../styles/variables/mixins.scss';

.rs-form {
  .rs-field {
    .rs-label {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      padding-right: 18px;
      position: relative;

      &.listing {
        align-items: flex-start;
        padding-top: 0.25rem;
      }

      @include until($desktop) {
        justify-content: flex-start;
        padding-bottom: 10px;
        padding-left: 0;
      }

      .required-asterisk {
        color: $red;
        font-weight: bold;
        position: relative;
        left: 3px;
      }

      p {
        margin: 0;
        text-align: right;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
        hyphens: none;

        @include until($desktop) {
          text-align: left;
        }
      }
    }
  }
}
